import {
	getAsync,
	postAsync,
	putAsync
} from 'common-frontend/utils/fetch-helper';
import { COURSES } from '../constants/courses';
import { STORES } from './stores';

// BUTTON RESET FLOW
export const RESET_SCORE = async (course) => {
	const {
		WalletStore: { setScore },
	} = STORES;
	const coursesToReset = course === 'all' ? COURSES : [ course ];

	for (const c of coursesToReset) {
		const response = await putAsync('/score', { course: c, score: 0 });

		if (response.status === 200) {
			setScore(c, 0);
		}
	}
};

// BUTTON SYNC WALLET
export const UPDATE_SCORE = async (course) => {
	const {
		WalletStore: { setScore },
	} = STORES;
	const curScoreRes = await getAsync('/score');

	if (curScoreRes.status === 200) {
		const data = await curScoreRes.json();
		const currentScore = data[course + 'Score'];
		console.info('current score ', currentScore);
		const newScore = currentScore + 25 + Math.floor(Math.random() * 25);
		console.info('new score', newScore > 100 ? 100 : newScore);

		const response = await putAsync('/score', { course, score: newScore > 100 ? 100 : newScore });

		if (response.status === 200) {
			const data = await response.json();
			setScore(course, data[course + 'Score']);
		}
	}
};

// BUTTON SHOW SCORE
export const GET_SCORE = async (course) => {
	const {
		WalletStore: { setScore },
	} = STORES;
	const response = await getAsync('/score');

	if (response.status === 200) {
		const data = await response.json();

		if (course === 'all') {
			COURSES.forEach(course => {
				setScore(course, data[course + 'Score']);
			});
		} else {
			setScore(course, data[course + 'Score']);
		}
	}
};

// BUTTON SEND PDF
export const SEND_PDF = async (course) => {
	await postAsync('/send-pdf', { course });
};