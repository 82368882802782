import React, { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { useStores } from 'common-frontend/components/store-provider';
import { REMOVE_RELATIONSHIP } from 'common-frontend/services/api-calls';
import { useTranslation } from 'react-i18next';
import { AcceptPopup } from 'common-frontend/components/accept-popup';
import { observer } from 'mobx-react';

export const RestartScenarioButton = observer(({ ...props }) => {
	const { t } = useTranslation();
	const [ removeUserPopup, setRemoveUserPopup ] = useState(false);
	const [ localLoader, setLocalLoader ] = useState(false);
	const { WalletStore, UserStore, AuthStore } = useStores();
	const { cleanWalletData } = WalletStore;
	const { user, userStatusCode } = UserStore;
	const { authProfile } = AuthStore;

	const removeRelationship = useCallback(() => {
		setLocalLoader(true);
		setRemoveUserPopup(false);

		REMOVE_RELATIONSHIP().finally(() => {
			cleanWalletData();
			setLocalLoader(false);
		});
	}, []);

	return (user || (authProfile && userStatusCode === '403')) && (
		<>
			<Button id="remove-user-button" disabled={localLoader} variant="contained" onClick={() => setRemoveUserPopup(true)} {...props}>{t('footer-user.remove-user.button')}</Button>
			<AcceptPopup id="remove-user-popup" title={ t('footer-user.remove-user.title') } text={ t('footer-user.remove-user.dialog') }
				isDanger={ true } isOpen={ removeUserPopup } onClose={ () => setRemoveUserPopup(false) } action={ removeRelationship } />
		</>
	);
});